import ExternalLinkSvg from "../svgComps/ExternalLinkSvg";

export  function SideBarPost({id,title, description, linkURL}) {

    const postContent =  (isLink) => (
        <div className={isLink && "post-item-link"} style={{borderBottom: "2px dashed rgb(146, 146, 146)"}}>
            <h2 style={{display:"inline"}}>{title}</h2>
            {isLink && 
                <div style={{display: "inline", padding: ".2rem .5rem 0 .5rem"}}>
                    <ExternalLinkSvg colour="rgb(15, 44, 89)" width="1rem" height="1rem" />
                </div>
            }
            <p className="side-bar-description" dangerouslySetInnerHTML={{__html:description}}/>
        </div>
    );

        return (
            <div className="side-bar-item"> 
                {linkURL && linkURL !== "" ? 
                    <a href={linkURL} target='_blank' rel='noopener noreferrer' className="Side-bar-post-link">
                        {postContent("☍")}
                    </a>
                :  postContent()}
                
            </div>
        )
}


export function SideBarHighLightPost({title, description, thumbnailURL, linkURL}){

    const postContent = (isLink) => (
        <div style={{borderBottom: "2px dashed rgb(146, 146, 146)"}}>
            <div className="side-bar-image-wrapper">
                <img width="100%" src={thumbnailURL} style={{borderRadius: "1rem"}}/>
            </div>
        
            <div style={{paddingBottom:".5rem"}}>
                <h1 style={{margin: "0.5rem 0", display:"inline"}}>{`${title}`} </h1>


                {isLink && 
                    <div style={{display: "inline", padding: ".5rem .5rem 0 0"}}>
                        <ExternalLinkSvg colour="rgb(15, 44, 89)" width="1rem" height="1rem" />
                    </div>
                }
            </div>
            <p className="side-bar-description">{description}</p>
        </div>
    );

    return(

        <div className="side-bar-highlight-item" >

            {linkURL && linkURL !== "" ?
                <a href={linkURL} target='_blank' rel='noopener noreferrer' className="Side-bar-post-link">
                    {postContent("☍")}
                </a> 
            : postContent()}
        </div>
    )
}
import './App.css';
import Home from './pages/Home';
import GuidoWorkshop from './pages/GuidoWorkshop';
import Contact from './pages/Contact'
import {BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import Blog from "./pages/Blog";


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />}  />
        <Route path='/Blog' element={<Blog />} />
        <Route path='/Contact' element={<Contact/>} />
        {/* <Route path='/GuidoWorkshop' element={<GuidoWorkshop />} /> */}
      </Routes>
    </Router>

  );
}

export default App;

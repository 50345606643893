import InstaSvg from "../svgComps/InstaSvg";
import YoutubeSvg from "../svgComps/YoutubeSvg";
import SpotifySvg from "../svgComps/SpotifySvg";
import BandcampSvg from "../svgComps/BandCampSvg";

const IconButton  = ({ svgComp, path}) => {
    return(
        <div className="svg-wrapper">
             <a style={{all: "unset", cursor:"pointer"}} href={path} target='_blank' rel='noopener noreferrer'>
                {svgComp}
             </a>
        </div>
    )

}


export default function Footer(){
    return (
        <footer className="Footer">         

            <div className="Footer-Icons-Wrapper">
                <IconButton 
                    svgComp={< InstaSvg colour={"white"} width={"100%"} height={"100%"} />} 
                    path={"https://www.instagram.com/tanhai_collective/"}
                />
                <IconButton 
                    svgComp={< YoutubeSvg colour={"white"} width={"100%"} height={"100%"} />} 
                    path={"https://www.youtube.com/@tanhaicollective7436"}
                />
                
                <p className="Footer-text">Tanhai <br/> Collective</p>

                <IconButton 
                    svgComp={< BandcampSvg colour={"white"} width={"100%"} height={"100%"} />} 
                    path={"https://tanhaicollective.bandcamp.com/"}
                />
                <IconButton 
                    svgComp={< SpotifySvg colour={"white"} width={"100%"} height={"100%"} />} 
                    path={"https://open.spotify.com/artist/26b6sds0w1dppBvAeSwv73?si=FzoZwCe-Rh26oE7hRak_jA"}
                />
                
            </div>
            
        </footer>
    )
}

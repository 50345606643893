import React, {useEffect, useState} from "react";
import HomeGallery from "../components/Home/HomeGallery";
import HomeSideBar from "../components/Home/HomeSideBar";
import HomeMain from "../components/Home/HomeMain";
import Footer from "../components/UniversalComps/Footer";
import {db} from '../firebaseConfig';
import { collection, getDocs } from "firebase/firestore";



export default function Home(){
    const [homeMainHeight, setHomeMainHeight] = useState(null);
    const [isGrid, setIsGrid] = useState(!((window.innerWidth < 950 && window.innerHeight > window.innerWidth) || window.innerWidth < 800));

    const [sideBarContent, setSideBarContent] = useState({highlightPost: null, posts: null});
    const [mainBioContent, setMainBioContent] = useState({text: null, imgURL: null});

    useEffect(() => {
        const getBioContent = async () => {
            try{
                const homeBioCollectionRef = collection(db, "home-bio");
                const bioData = await getDocs(homeBioCollectionRef);
                setMainBioContent({
                    text: bioData.docs[0].data().Bio, 
                    imgURL: bioData.docs[0].data().imgURL
                });    
            }catch(err){
                console.log("db failure at home bio page, loading bio content");
            }
        }
        getBioContent();


        const getPosts = async () => {
            let highlightContent;
            let postsContent;
            try{
                //Getting the highlight post from firebase
                const sideBarHLCollectionRef = collection(db, "home-side-bar-highlight");
                const hlData = await getDocs(sideBarHLCollectionRef);
                highlightContent = hlData.docs.map((doc) => ({...doc.data(), id: doc.id}));
            }catch(error){
                console.log(`db failure at home page sidebar Highlighted content, error message: ${error}`)
            }

            try{
                //Getting the rest of the posts from firebase
                const sideBarCollectionRef = collection(db, "home-side-bar");
                const data = await getDocs(sideBarCollectionRef);
                const formatedData = Array.from(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
                formatedData.sort((a,b) => b.date.seconds - a.date.seconds);
                
                postsContent = formatedData;

                setSideBarContent({highlightPost: highlightContent, posts: postsContent});
            }
            catch(error){
                console.log(`db failure at home page sidebar, error message: ${error}`);
            }
        };

        getPosts();
    },[]);


    const callbackMainHeight = (e) => {
        setHomeMainHeight(e);
    };

    //checking dimensions of viewport to define the layout of the home page
    useEffect(() => {
        const handleResize = () => {
            if((window.innerWidth < 950 && window.innerHeight > window.innerWidth) || window.innerWidth < 800){
                setIsGrid(false);
            }
            else{
                setIsGrid(true);
            }
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])
    
    
    return (
        <div style={{width:"100%"}}>
            <div className="Home-page">
                <HomeSideBar maxHeight={homeMainHeight} isGrid={isGrid} highlightPost={sideBarContent.highlightPost} sideBarPosts={sideBarContent.posts}/>
                <HomeMain callbackMainHeight={callbackMainHeight} isGrid={isGrid} imgURL={mainBioContent.imgURL} bioText={mainBioContent.text}/> 
                <HomeGallery />
            </div>
            <Footer />
        </div>  
    )
}


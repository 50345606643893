import { useState, useEffect } from "react"
import NavBar from "../components/UniversalComps/NavBar"
import {db} from '../firebaseConfig';
import { collection, getDocs } from "firebase/firestore";
import Footer from "../components/UniversalComps/Footer";



export default function Blog() {

    const [blogContent, setBlogContent] = useState();

    useEffect(() => {
        const getPost = async () => {
            try {
                const blogCollectionRef = collection(db, "blog-posts");
                const postsData = await getDocs(blogCollectionRef);
                const formatedData = postsData.docs.map(doc => ({...doc.data(), id: doc.id}));
                console.log(formatedData);
                formatedData.sort((a,b) => b.publishDate.seconds - a.publishDate.seconds);
                setBlogContent(formatedData);
            }catch(error){
                console.log("db failure at blog page, loading blog post content");
            }
        }
        getPost();
    }, []);

    //text needs to be preprocessed to have \n for new lines
    const BlogPost = ({title,imgURL,text, pubDate}) => {
        const date = new Date(pubDate.seconds * 1000);
        const formatedDate = date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear();
        return (
            <div className="Blog-post-container">
                <h2 className="Blog-post-title">  {title}</h2>
                <p className="Blog-publish-date">{'[' + formatedDate + ']'}</p>
                {/* <img src={imgURL} className="Blog-post-image"/> */}
                <p className="Blog-post-content" dangerouslySetInnerHTML={{__html:text}} />
            </div>
        )
    } 


    return (

        <div className="Blog-page-wrapper">

            <div className="Blog-title">
                <h1 className="Home-title-text"> Blog / Journal</h1>
            </div>

            <NavBar currentPage={"Blog"} style={{margin:"2rem 0"}}/>

            <div className="Blog-posts-container">
                {blogContent && blogContent.map(post => {
                    return <BlogPost 
                        title={post.title}
                        key={post.id}
                        text={post.text}
                        imgURL={post.imgURL}
                        pubDate={post.publishDate}
                    />
                }   
                )}
            </div>

            <Footer />
            
        </div>

    )
}

// title
// nav bar
// most recent post:
//     title:
//     date:
//     img(opt):
//     formated text:
// next post
// and the next
// FOOTER / if scroll to bottom load more

import React from "react";
import {SideBarPost, SideBarHighLightPost} from "./SideBarPost";


export default function HomeSideBar({maxHeight, highlightPost, sideBarPosts, isGrid}){


    return (
        <div className="Home-side-wrapper" style={{maxHeight: isGrid ? maxHeight : "none"}}> 
            <div className="Home-side-content">
                {
                    highlightPost && highlightPost.map(post => <SideBarHighLightPost 
                    key={post.id}
                    id={post.id} 
                    title={post.title} 
                    description={post.description} 
                    thumbnailURL={post.thumbnailURL}
                    linkURL={post.linkURL}
                    />)
                }
                {
                    sideBarPosts && sideBarPosts.map(post => <SideBarPost 
                    key={post.id}
                    id={post.id} 
                    title={post.title} 
                    description={post.description} 
                    thumbnailURL={post.thumbnailURL}
                    linkURL={post.linkURL}
                    />)
                }

            </div>
        </div>
    )
}
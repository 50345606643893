import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';

export default function ContactForm()  {
    const formRef = useRef();
    const [submitButtonText, setSubmitButtonText] = useState("Send");


    const SERVICE_ID = "service_m7e84xl";
    const TEMPLATE_ID = "template_n0u19l6";
    const PUBLIC_KEY = "Qn_IxSGokey1uqV1h";

    const sendEmail = (e) => {
        e.preventDefault();
        setSubmitButtonText("...");
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, {
            publicKey: PUBLIC_KEY,
        })
        .then(
            () => {
                setSubmitButtonText("Sent");
                setTimeout(() =>{
                    formRef.current.reset();
                    setSubmitButtonText("Send");
                }, 2000);
            },
            (error) => {
                console.log('Failed to send please try again later', error.text);
                setSubmitButtonText("Failed");
                setTimeout(() =>{
                    formRef.current.reset();
                    setSubmitButtonText("Send");
                }, 2000);
            }
        )

    }


    return(
        <form ref={formRef} style={{height:"100%", width: "100%"}} onSubmit={sendEmail}  >
            <div id="contact-form">
                <div className="Label-text-container">
                    <label>Name: </label>
                    <input className='Input-text' type='text' name='user_name' />
                </div>
                
                <div className="Label-text-container">
                    <label>Email: </label>
                    <input className='Input-text' type='text' name='user_email' />
                </div>
                
                <div className="Label-text-area-container">
                    <label>Message: </label>
                    <textarea  name='message' />
                </div>
                
                <input id="form-send-button" type='submit' value={submitButtonText} />
            </div>
        </form>
    );
}


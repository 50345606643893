import Footer from '../components/UniversalComps/Footer';
import NavBar from '../components/UniversalComps/NavBar';
import ContactForm from '../components/Contact/ContactForm';

export default function Contact() {

    return (
        <div className='Contact-page'>
            <div className='Blog-title'>
                <h1 className='Home-title-text'>Get in Touch!</h1>
            </div>
            <NavBar currentPage={"Contact"}/>

            <div className='Contact-form-wrapper'>
                <ContactForm />
            </div>
            
            <Footer />
        </div>
    )
}

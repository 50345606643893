import {useState ,useEffect } from 'react';
import {db, storage} from '../../firebaseConfig';
import { collection, getDocs } from "firebase/firestore";


const GalleryItem = ({imgURL, linkURL, id, title}) =>{
    return (
        <div className='Gallery-item'>
            <a  href={linkURL} target='_blank' rel='noopener noreferrer'>
                <img className="Gallery-item-img" src={imgURL} />
            </a>
        </div>
    )
}



export default function HomeGallery(){
    const [galleryItems, setGalleryItems] = useState(null);


    useEffect(() => {
        const getItems = async () => {
            try{
                const galleryCollectionRef = collection(db, "home-gallery");
                const data = await getDocs(galleryCollectionRef);
                setGalleryItems(data.docs.map((doc) => ({...doc.data(), id: doc.id})));

            }catch(error){
                console.log(`Firestore failure at home page gallery, error message: ${error}`);
            }
        }

        getItems();
    }, [])


   return(
        <div className="Home-gallery">
            <div className="Gallery-item-container">

                {
                    galleryItems && galleryItems.map(item => <GalleryItem 
                    key={item.id} 
                    title={item.title}
                    imgURL={item.imgURL} 
                    linkURL={item.linkURL}/>)
                }

            </div>
        </div>
   ) 
}
import tanhaiGroupPic from "../../assets/tanhaiGroupPic.jpg"
import { useEffect, useRef } from "react";
import NavBar from "../UniversalComps/NavBar";

export default function HomeMain({callbackMainHeight, imgURL, bioText, isGrid}) {
    const container = useRef(null);
 
    const calculateHeight = () => {
        if(container){
            let realHeight = 0;
            Array.from(container.current.children).forEach(
                (child) => {
                    console.log(child, child.clientHeight);
                    realHeight+= child.offsetHeight;
                }
            );
            return realHeight;
        }
        else{
            console.log("Error calculating main height,container ref is null");
            return null;
        }
    }

    useEffect(() => {
        const handleResize = () => {
            callbackMainHeight(calculateHeight()); 
        };

        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);


    return(
        <div className="Home-main-wrapper" ref={container}>
            
            <NavBar 
                style={isGrid ? 
                    { width: "100%", marginBottom: "2rem"} : 
                    {width:"100%", order:"2"}} 
                currentPage={"Home"} />
            <div className="Home-title-container">
                <h1 className="Home-title-text"> Tanhai Collective</h1>
            </div>
            <div className="Home-bio">
                <img className="Home-bio-img" onLoad={()=>{}} src={imgURL ? imgURL : tanhaiGroupPic} alt="Group Image"/>
                <div className="Home-bio-text-wrapper">
                    <p className="Home-bio-text" dangerouslySetInnerHTML={{__html: bioText}} />
                </div>
            </div>
        </div> 
    )
}
import { Link } from 'react-router-dom';

export default function NavBar({style, currentPage}) {

    const NavItem = ({name, path, isExternal}) => {

        return (
            <div className = "Home-nav-item" 
                style = {{cursor:"pointer"}}
            >
                {isExternal ?
                    <a style={{all: "unset"}} href={path} target='_blank' rel='noopener noreferrer'> <p>{name}</p> </a>
                 :
                    <Link style={{all: "unset"}}to={path}><p>{name}</p></Link>
                }
                
            </div>
        )
    }
    return (
        <div  className="Nav-container" style={{...style}}>
            {currentPage == "Home" ? <></> : <NavItem name="Home" path="/" isExternal={false} />}
            <NavItem name="Releases" path="https://tanhaicollective.bandcamp.com/music" isExternal={true}/>
            {currentPage == "Blog" ? <></> : <NavItem name="Journal/Blog" path="/Blog" isExternal={false}/>}
            {currentPage == "Contact" ? <></> : <NavItem name="Get in Touch" path="/Contact" isExternal={false} />}
            <NavItem name="Merch" path="https://tanhaicollective.bandcamp.com/merch" isExternal={true}/>
            <NavItem name="Shows" path="https://www.songkick.com/artists/10267986-tanhai-collective" isExternal={true}/>
        </div>
    )
}